<template>
  <div>
    <div class="media-uploader">
      <vue-dropzone
        v-on:vdropzone-complete="uploadCompleted"
        :useCustomSlot="true"
        ref="myVueDropzone"
        id="dropzone"
        :options="dropzoneOptions"
        @vdropzone-removed-file="removeFile"
      >
        <div class="dropzone-custom-content">
          <h3>Drag and drop to upload content!</h3>
          <div>...or click to select a file from your computer</div>
        </div>
      </vue-dropzone>
    </div>

    <div class="accepted-files mt-2" v-if="files.length">
      <h4>Accepted Files</h4>
      <div class="gallery-grid">
        <div v-for="media in files" :key="media.key" class="media-item">
          <div class="media-item-wrapper">
            <div class="media-item-inner-wrapper">
              <div class="media-thumb">
                <img :xlink:href="media.url" :src="media.url" />
              </div>
              <div class="media-details">
                <div class="media-name">
                  <h2>
                    {{ media.key }}
                  </h2>
                </div>
              </div>
              <div class="media-action">
                <b-button
                  v-clipboard:copy="media.url"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  variant="warning"
                  class="btn-icon"
                >
                  <feather-icon icon="ClipboardIcon"></feather-icon>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { getUserToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {
      dropzoneOptions: {
        url: `${process.env.VUE_APP_SERVER_URL}/api/gallery/upload`,
        paramName: "media",
        method: "POST",
        thumbnailWidth: 150,
        addRemoveLinks: true,
        dictRemoveFile: "Remove",
        dictRemoveFileConfirmation:
          "This will delete the file from the server. Do you wish to continue?",
        addRemoveLinks: true,
        duplicateCheck: true,
        acceptedFiles: ".jpeg,.jpg,.png,.gif",
        headers: {
          Authorization: `Bearer ${getUserToken()}`,
        },
      },
      files: [],
    };
  },
  components: {
    BButton,
    vueDropzone: vue2Dropzone,
    ToastificationContent,
  },
  methods: {
    uploadCompleted(response) {
      console.log(response);
      if (response.xhr && response.xhr.response) {
        let responseData = JSON.parse(response.xhr.response);
        let acceptedFiles = responseData.acceptedFiles;

        for (let i = 0; i < acceptedFiles.length; i++) {
          const acceptedFile = acceptedFiles[i];
          this.files.push(acceptedFile);
        }
      }
    },
    removeFile() {},
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Text copied",
          icon: "BellIcon",
        },
      });
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Failed to copy texts!",
          icon: "BellIcon",
        },
      });
    },
  },
};
</script>

<style scoped>
.vue-dropzone {
  border: 2px dashed #e5e5e5;
  font-family: "Montserrat", Helvetica, Arial, serif;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
}
.media-item {
  grid-column: span 3 / auto;
  max-width: 100%;
}
.media-item-wrapper {
  background: rgb(33, 33, 52);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(50, 50, 77);
  box-shadow: rgba(3, 3, 5, 0.2) 1px 1px 10px;
  height: 100%;
}
.media-thumb {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  height: 10.25rem;
  width: 100%;
  background: repeating-conic-gradient(
      rgb(24, 24, 38) 0%,
      rgb(24, 24, 38) 25%,
      transparent 0%,
      transparent 50%
    )
    50% center / 20px 20px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.media-details {
  padding: 8px 12px;
}
.media-name {
  word-break: break-all;
}

.media-name h2 {
  font-size: 0.75rem;
  line-height: 1.33;
  font-weight: 600;
  color: rgb(255, 255, 255);
}
.media-item-inner-wrapper {
  position: relative;
}
.media-action {
  position: absolute;
  top: 10px;
  right: 15px;
}
</style>
